import React from 'react';
import {Box, Flex, Image, Text, useColorModeValue} from '@chakra-ui/react';
import Layout from './../base/Layout';
import { Link } from 'gatsby';
import SearchBar from '../general/SearchBar';
import G3LogoDark from '../../../assets/g3-dark-logo.svg';
import G3LogoLight from "../../../assets/g3-light-logo.svg";

export default () => {
    const logoImage = useColorModeValue(G3LogoLight, G3LogoDark);

    return (
        <Layout noContainer showConnectButton showNavLinks>
            <Flex
                direction="column"
                justify="center"
                align="center"
                margin="0 auto"
                minHeight="50vh"
            >
                <Box margin={['1rem 0', '5rem 0']} textAlign="center">
                    <Link
                        to="/"
                        style={{
                            textDecoration: `none`,
                        }}
                    >
                        <Image src={logoImage} width={['15rem', '25rem']} />
                    </Link>
                    <Text opacity="0.5" marginTop="0.5rem">
                        ALPHA Version
                    </Text>
                    {/*<Text marginTop={['1rem', '2rem']}>*/}
                    {/*    Where creators meet the Metaverse.*/}
                    {/*</Text>*/}
                </Box>

                <Box width={['90vw', '40vw']}>
                    <SearchBar />
                </Box>
            </Flex>
        </Layout>
    );
};
