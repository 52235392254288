import * as React from 'react';
import G3TokenGateProvider from '../components/Dashboard/contexts/G3TokenGateContext';
import Home from '../components/Home';
import QuickViewNFTProvider from '../components/MetaPage/contexts/QuickViewNFTContext';

export default () => {
    return (
        <G3TokenGateProvider>
            <QuickViewNFTProvider>
                <Home />
            </QuickViewNFTProvider>
        </G3TokenGateProvider>
    );
};
